import React from "react";
import styles from './layout.module.scss';
import Header from '../header/header';

interface Props {
    children?: any
};

const Layout = ({ children }: Props) => {
  return (
    <div className={styles.layout}>
      <Header />
      <main>{children}</main>
      <footer>
        © Terminal News Org {new Date().getFullYear()}
      </footer>
    </div>
  );
}

export default Layout;