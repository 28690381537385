import React from "react"
import { Link, StaticQuery, graphql } from 'gatsby';

import styles from './header.module.scss';

const Header = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allContentfulCategory {
          edges {
            node {
              title
            }
          }
        }
      }
    `}
    render={data => (
      <header className={styles.header}>
        <div>
          <Link to='/' className={styles.logo}>Logo</Link>
        </div>
        <div className={styles.categories}>
          {data.allContentfulCategory.edges.map((category: any) => {
            return (
              <Link to={`/cat/${category.node.title.toLowerCase()}`}>
                <div className={styles.category}>
                  {category.node.title}
                </div>
              </Link>
            )
          })}
        </div>
      </header>
    )}
  />
);

export default Header;
